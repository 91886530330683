// extracted by mini-css-extract-plugin
export var buttonCreateContainer = "ResourceNewForm__buttonCreateContainer__TRdio";
export var column = "ResourceNewForm__column__KogaU";
export var documentRow = "ResourceNewForm__documentRow__SqKP3";
export var editor = "ResourceNewForm__editor__rHvOl";
export var fileNameText = "ResourceNewForm__fileNameText__O8q0a";
export var fileSizeText = "ResourceNewForm__fileSizeText__oXRPF";
export var flex = "ResourceNewForm__flex__nBGAo";
export var flexColumn = "ResourceNewForm__flexColumn__g8Qlc";
export var flexContainer = "ResourceNewForm__flexContainer__yRL9u";
export var gap1 = "ResourceNewForm__gap1__qlPlK";
export var gap2 = "ResourceNewForm__gap2__AoFFF";
export var gap3 = "ResourceNewForm__gap3__zcGak";
export var gap4 = "ResourceNewForm__gap4__Bvx_n";
export var gap5 = "ResourceNewForm__gap5__VkFLX";
export var grid = "ResourceNewForm__grid__hMdzX";
export var iconDelete = "ResourceNewForm__iconDelete__B_iFa";
export var iconView = "ResourceNewForm__iconView__WJK5A";
export var labelContainer = "ResourceNewForm__labelContainer__DSHnC";
export var resourceDetailField = "ResourceNewForm__resourceDetailField__PFFmQ";
export var row = "ResourceNewForm__row__ztC_H";
export var uploadDocumentButton = "ResourceNewForm__uploadDocumentButton__zknMJ";